import Vue from 'vue'
import AdminTitle from '../components/admin-title/admin-title.vue'

import TpTable from '@/components/tp-table/index.vue'
import FileUpload from "@/components/file-upload/index.vue";

const components = [AdminTitle, TpTable, FileUpload]

export default function install() {
  components.forEach(item => Vue.component(item.name, item))
}
