// 常量路由
export const constantRoutes = [
  {
    path: '/',
    redirect: '/channel',
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    meta: {
      hidden: true
    },
    component: () => import('@/views/login/index')
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: {
  //     icon: 'el-icon-s-home',
  //     name: '首页',
  //     affix: true
  //   },
  //   component: () => import('../views/home/index.vue')
  // },
  {
    path: '/channel',
    name: 'Channel',
    redirect: '/channel/channel-order',
    component: () => import('@/views/channel/index.vue'),
    meta: { name: '渠道管理', icon: 'el-icon-message-solid' },
    children: [
      // {
      //   path: '/channel/channel-list',
      //   name: 'ChannelList',
      //   component: () => import('@/views/channel/channel-list/list.vue'),
      //   meta: { name: '渠道列表' }
      // },
      // {
      //   path: '/channel/channel-list/add',
      //   name: 'ChannelAdd',
      //   component: () => import('@/views/channel/channel-list/addOrEdit.vue'),
      //   meta: { name: '新增渠道', hidden: true }
      // },
      // {
      //   path: '/channel/channel-list/edit/:id?',
      //   name: 'ChannelEdit',
      //   component: () => import('@/views/channel/channel-list/addOrEdit.vue'),
      //   meta: { name: '编辑渠道', hidden: true }
      // },
      {
        path: '/channel/channel-order',
        name: 'ChannelOrder',
        component: () => import('@/views/channel/channel-order/list.vue'),
        meta: { name: '渠道订单' }
      },
      // {
      //   path: '/channel/channel-count',
      //   name: 'ChannelCount',
      //   component: () => import('@/views/channel/channel-count/list.vue'),
      //   meta: { name: '渠道统计' }
      // }
    ]
  }
]

// 任意路由
export const anyRoutes = [
  //
]

// 动态路由
export const asyncRoutes = () => []
